import { Box, Ground, Polygon } from "shared/interfaces/firestore";
import useStoreWithUndo from "store/store";
import { AmbientLight, DirectionalLight } from "store/world/light/light.types";
import { Blueprint } from "store/world/blueprint/blueprint.types";
import { IfcModel } from "store/world/ifc/ifc.interface";
import { BoxComponents } from "store/world/box/box.interface";

export const useWorldCameraMode = () =>
  useStoreWithUndo((state) => state.worldCameraMode);

export const useWorldLookAt = () =>
  useStoreWithUndo((state) => state.worldLookAt);

export const useWorldToolMode = () =>
  useStoreWithUndo((state) => state.worldToolMode);

export const useWorldRenderMode = () =>
  useStoreWithUndo((state) => state.worldRenderMode);

export const useWorldSelectedIds = () =>
  useStoreWithUndo((state) => state.worldSelectedIds);

export const useWorldMeasurementsHidden = () =>
  useStoreWithUndo((state) => state.worldMeasurementsHidden);

export const useWorldActions = () =>
  useStoreWithUndo((state) => state.worldActions);

export const getSelectedObjects = () => {
  const state = useStoreWithUndo.getState();

  const ids = state.worldSelectedIds;

  return {
    polygons: state.polygons.filter((polygon) => ids.includes(polygon.id)),
    boxes: state.boxes.filter((box) => ids.includes(box.id)),
    blueprints: state.blueprints.filter((blueprint) =>
      ids.includes(blueprint.id)
    ),
    grounds: state.grounds.filter((ground) => ids.includes(ground.id)),
    lightsAmbient: state.lightsAmbient.filter((light) =>
      ids.includes(light.id)
    ),
    ligthsDirectional: state.lightsDirectional.filter((light) =>
      ids.includes(light.id)
    ),
    ifcModels: state.ifcModels.filter((ifc) => ids.includes(ifc.id)),
    measurements: state.measurements.filter((measurement) =>
      ids.includes(measurement.id)
    )
  };
};

export const getComponentsFromIds = (ids: string[]): BoxComponents => {
  const state = useStoreWithUndo.getState();
  return {
    standards: state.standards.filter((standard) => ids.includes(standard.id)),
    ledgers: state.ledgers.filter((ledger) => ids.includes(ledger.id)),
    toeBoards: state.toeBoards.filter((toeBoard) => ids.includes(toeBoard.id)),
    baseCollars: state.baseCollars.filter((baseCollar) =>
      ids.includes(baseCollar.id)
    ),
    baseBoards: state.baseBoards.filter((baseBoard) =>
      ids.includes(baseBoard.id)
    ),
    basePlates: state.basePlates.filter((basePlate) =>
      ids.includes(basePlate.id)
    ),
    consoles: state.consoles.filter((console) => ids.includes(console.id)),
    planks: state.planks.filter((plank) => ids.includes(plank.id)),
    stairways: state.stairways.filter((stairway) => ids.includes(stairway.id)),
    stairwayGuardRails: state.stairwayGuardRails.filter((rail) =>
      ids.includes(rail.id)
    ),
    stairwayInnerGuardRails: state.stairwayInnerGuardRails.filter((rail) =>
      ids.includes(rail.id)
    ),
    diagonalBraces: state.diagonalBraces.filter((diagonalBrace) =>
      ids.includes(diagonalBrace.id)
    ),
    beamSpigots: state.beamSpigots.filter((beamSpigot) =>
      ids.includes(beamSpigot.id)
    ),
    anchors: state.anchors.filter((anchor) => ids.includes(anchor.id))
  };
};

export const updateObjects = (
  ids: string[],
  data:
    | Partial<Polygon>
    | Partial<Box>
    | Partial<Blueprint>
    | Partial<Ground>
    | Partial<AmbientLight>
    | Partial<DirectionalLight>
    | Partial<IfcModel>
) => {
  const state = useStoreWithUndo.getState();

  /** Polygons */
  const updatedPolygons = state.polygons.filter((polygon) =>
    ids.includes(polygon.id)
  );

  if (updatedPolygons.length > 0) {
    state.polygonActions.update(
      updatedPolygons.map((p) => p.id),
      data as Partial<Polygon>
    );
  }

  /** Boxes */
  const updatedBoxes = state.boxes.filter((box) => ids.includes(box.id));

  if (updatedBoxes.length > 0) {
    state.boxActions.update(
      updatedBoxes.map((b) => b.id),
      data as Partial<Box>
    );
  }

  /** Blueprints */
  const updatedBlueprints = state.blueprints.filter((blueprint) =>
    ids.includes(blueprint.id)
  );

  if (updatedBlueprints.length > 0) {
    state.blueprintActions.update(
      updatedBlueprints.map((b) => b.id),
      data as Partial<Blueprint>
    );
  }

  /** Ifc models */
  const updatedIfcModels = state.ifcModels.filter((ifcModel) =>
    ids.includes(ifcModel.id)
  );

  if (updatedIfcModels.length > 0) {
    state.ifcActions.update(
      updatedIfcModels.map((b) => b.id),
      data as Partial<IfcModel>
    );
  }

  /** Grounds */
  const updatedGrounds = state.grounds.filter((ground) =>
    ids.includes(ground.id)
  );

  if (updatedGrounds.length > 0) {
    state.groundActions.update(
      updatedGrounds.map((g) => g.id),
      data as Partial<Ground>
    );
  }

  /** LightsAmbient */
  const updatedLightsAmbient = state.lightsAmbient.filter((light) =>
    ids.includes(light.id)
  );

  if (updatedLightsAmbient.length > 0) {
    state.lightActions.updateAmbient(
      updatedLightsAmbient.map((l) => l.id),
      data as Partial<AmbientLight>
    );
  }

  /** LightsDirectional */
  const updatedLightsDirectional = state.lightsDirectional.filter((light) =>
    ids.includes(light.id)
  );

  if (updatedLightsDirectional.length > 0) {
    state.lightActions.updateDirectional(
      updatedLightsDirectional.map((l) => l.id),
      data as Partial<DirectionalLight>
    );
  }
};

export const updateObjectsByLengthOnAxis = (
  ids: string[],
  value: number,
  axis: "x" | "z"
) => {
  const state = useStoreWithUndo.getState();

  /** Polygons */
  const updatedPolygons = state.polygons.filter((polygon) =>
    ids.includes(polygon.id)
  );

  if (updatedPolygons.length > 0) {
    state.polygonActions.updatePointsByLengthOnAxis(
      updatedPolygons.map((p) => p.id),
      value,
      axis
    );
  }
};
