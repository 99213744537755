import { half, minus, plus, round, times } from "math";
import { Euler, Vector3, Vector3Tuple } from "three";
import { Ledger } from "world/core/Ledger/Ledger.types";
import { Console } from "world/core/Console/Console.types";
import { getEndPointFromStartPoint, isPointOnLineLimited } from "math/vectors";
import { Standard } from "world/core/Standard/Standard.types";

export const getStandardsRailTop = (
  guardRailOptions: [boolean, boolean, boolean, boolean]
) => {
  return [
    guardRailOptions[3] || guardRailOptions[0], // left or front
    guardRailOptions[1] || guardRailOptions[0], // right or front
    guardRailOptions[1] || guardRailOptions[2], // right or back
    guardRailOptions[3] || guardRailOptions[2] // left or back
  ] as [boolean, boolean, boolean, boolean];
};

export const calcCenterPositionsAlongLine = (props: {
  start: Vector3;
  end: Vector3;
  partitions: number[];
  spacing?: number;
}) => {
  const { start, end, partitions } = props;
  const spacing = props.spacing ?? 0;

  const direction = end.clone().sub(start);
  const directionLength = direction.length();
  const directionNorm = direction.clone().normalize();
  let totalPartitionLength = partitions.reduce(
    (acc, partition) => plus(acc, partition),
    0
  );
  totalPartitionLength = plus(
    totalPartitionLength,
    times(spacing, partitions.length - 1)
  );
  const deltaLengthAndPartitions = minus(directionLength, totalPartitionLength);

  const positions: Vector3[] = [];
  let accumulatedLength = 0;

  partitions.forEach((partition) => {
    let centerPos = plus(
      accumulatedLength,
      half(partition),
      half(deltaLengthAndPartitions)
    );

    positions.push(
      start.clone().add(directionNorm.clone().multiplyScalar(centerPos))
    );
    accumulatedLength = plus(accumulatedLength, partition, spacing);
  });

  return positions;
};

/**
 * Calculates the amount of paritions that can fill the distance.
 * The algorithm will use the largest partitions first.
 * @param length The distance to fill
 * @param partitions The partitions to use
 * @returns The partitions used and the remaining length
 */
export const calcLengthPartitions = (length: number, partitions: number[]) => {
  let remainingLength = length;
  const usedPartitions: number[] = [];
  const sortedPartitions = partitions.sort((a, b) => b - a);

  sortedPartitions.forEach((partition) => {
    while (remainingLength >= partition) {
      usedPartitions.push(partition);
      remainingLength = minus(remainingLength, partition);
    }
  });
  return {
    partitions: usedPartitions,
    remainingLength: remainingLength
  };
};

export const getBoxStandardPositions = (props: {
  position: Vector3Tuple;
  depth: number;
  width: number;
  rotation: Vector3Tuple;
  rayHitsYPosition: [number, number, number, number];
}) => {
  const { position, depth, width, rotation, rayHitsYPosition } = props;
  const boxCenter = new Vector3(...position);
  const boxRotation = new Euler(...rotation);

  const FL = boxCenter
    .clone()
    .setY(rayHitsYPosition[0])
    .addP(new Vector3(half(width), 0, half(depth)));
  const FR = boxCenter
    .clone()
    .setY(rayHitsYPosition[1])
    .addP(new Vector3(-half(width), 0, half(depth)));
  const BR = boxCenter
    .clone()
    .setY(rayHitsYPosition[2])
    .addP(new Vector3(-half(width), 0, -half(depth)));
  const BL = boxCenter
    .clone()
    .setY(rayHitsYPosition[3])
    .addP(new Vector3(half(width), 0, -half(depth)));
  const rotatedPoints = rotatePointsAroundCenter({
    points: [FL, FR, BR, BL],
    rotation: boxRotation,
    center: boxCenter
  });

  return {
    FL: rotatedPoints[0],
    FR: rotatedPoints[1],
    BR: rotatedPoints[2],
    BL: rotatedPoints[3]
  };
};

export const rotatePointsAroundCenter = (props: {
  points: Vector3[];
  rotation: Euler;
  center?: Vector3;
}) => {
  const { points, rotation, center } = props;
  const centerPoint = center || new Vector3(0, 0, 0);

  return points.map((point) => {
    const rotatedPoint = point.clone().subP(centerPoint);
    rotatedPoint.applyEulerP(rotation);
    return rotatedPoint.addP(centerPoint);
  });
};

export const rotateStandardPositions = (props: {
  standardPositions: Vector3[];
  oldRotation: Euler;
  newRotation: Euler;
  oldCenter: Vector3;
  newCenter: Vector3;
}) => {
  const { standardPositions, oldRotation, newRotation, oldCenter, newCenter } =
    props;

  return standardPositions.map((standardPosition) => {
    const rotatedPoint = standardPosition.clone().subP(oldCenter);
    rotatedPoint.applyEulerP(
      new Euler(-oldRotation.x, -oldRotation.y, -oldRotation.z)
    );
    rotatedPoint.applyEulerP(newRotation);
    return rotatedPoint.addP(newCenter);
  });
};

export const roundVector = (vector: Vector3) => {
  const rounded = vector.toArray().map((pos) => round(pos, 2)) as Vector3Tuple;
  return new Vector3(...rounded);
};

export const splitStandards = (props: {
  standardsToSplit: Standard[];
  positions: Vector3Tuple[];
}) => {
  const { standardsToSplit, positions } = props;

  splitComponent({
    componentsToSplit: standardsToSplit,
    positions,
    componentDirectionY: true
  });
};

const POSITION_VECTOR = new Vector3();

export const splitComponent = (props: {
  componentsToSplit: (Standard | Ledger | Console)[];
  positions: Vector3Tuple[];
  componentDirectionY?: boolean;
}) => {
  const { componentsToSplit, positions, componentDirectionY } = props;

  const componentsStartAndEndPositions = componentsToSplit.map((component) => ({
    start: new Vector3(...component.position),
    end: component.endPosition
      ? new Vector3(...component.endPosition)
      : componentDirectionY
        ? new Vector3(...component.position).setY(
            plus(component.position[1], component.length)
          )
        : getEndPointFromStartPoint({
            startPosition: component.position,
            length: component.length,
            rotation: component.rotation
          })
  }));

  positions.forEach((position) => {
    POSITION_VECTOR.set(...position);
    componentsToSplit.forEach((component, idx) => {
      const componentStart = componentsStartAndEndPositions[idx].start;
      const componentEnd = componentsStartAndEndPositions[idx].end;

      const isPointOnComponent = isPointOnLineLimited({
        pointA: componentStart,
        pointB: componentEnd,
        pointToCheck: POSITION_VECTOR
      });
      if (isPointOnComponent) {
        const distance = componentStart.distanceToP(POSITION_VECTOR);
        if (component.splits) {
          if (!component.splits.includes(distance)) {
            component.splits.push(distance);
          }
        } else {
          component.splits = [distance];
        }
      }
    });
  });
};

export const splitLedgers = (props: {
  ledgersToSplit: Ledger[];
  positions: Vector3Tuple[];
}) => {
  const { ledgersToSplit, positions } = props;
  splitComponent({
    componentsToSplit: ledgersToSplit,
    positions
  });
};

export const splitConsoles = (props: {
  consolesToSplit: Console[];
  positions: Vector3Tuple[];
}) => {
  const { consolesToSplit, positions } = props;

  splitComponent({
    componentsToSplit: consolesToSplit,
    positions
  });
};
