import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { FemSlice } from "./fem.interface";
import { sliceResetFns } from "store/store";

/** Initial state */
const initialFemState = {
  femShow: false,
  femDisplacements: []
};

/** Slice creation */
const createFemSlice: StateCreator<Store, [], [], FemSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialFemState));

  /** Return state */
  return {
    ...initialFemState,
    femActions: {
      setFemDisplacements: (displacements) =>
        set({ femDisplacements: displacements })
    }
  };
};

export default createFemSlice;
