import { plus } from "math";

export const PLANK_WIDTH = 0.32;
export const TOE_BOARD_WIDTH = 0.15;
export const TOE_BOARD_OFFSET = 0.01;
export const AVAILABLE_STANDARD_LENGTHS = [0.5, 1, 1.5, 2, 2.5, 3, 4];
export const DEFAULT_FALL_PROTECTION_HEIGHT = 1;
export const STAIR_WIDTH = 0.63;
export const DEFAULT_STAIR_HEIGHT = 2;
export const BASE_PLATE_LENGTH = 0.24;
export const BASE_COLLAR_LENGTH = 0.2;
export const BASE_COLLAR_TOP_HEIGHT = 0.06;
export const BASE_BOARD_LENGTH = 0.2;
export const BASE_BOARD_HEIGHT = 0.045;
export const BASE_PLATE_BOARD_THICKNESS = 0.01;
export const SPINDLE_NUT_THICKNESS = 0.01;
export const SPIGOT_LENGTH = 0.15;

export const MIN_STANDARD_HEIGHT = plus(
  BASE_BOARD_HEIGHT,
  BASE_PLATE_BOARD_THICKNESS,
  SPINDLE_NUT_THICKNESS
);
