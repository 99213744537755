import { SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import AllroundModul from "./layher/allroundModul/allroundModul";
import { Supplier } from "./scaffold.interface";

export const getScaffoldClass = (
  supplier: SCAFFOLD_SUPPLIER
): Supplier | null => {
  if (supplier === SCAFFOLD_SUPPLIER.LAYHER) {
    return new AllroundModul();
  }
  return null;
};
