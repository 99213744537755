import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import { Box } from "shared/interfaces/firestore";
import { BoxComponents } from "store/world/box/box.interface";

export interface Supplier {
  getPlankMaterials: () => string[];
  getBayBoxLengths: () => number[];
  getStandardLengths: () => number[];
  generateBayBoxComponents: (boxProps: BayBoxProps, allBoxes?: Box[]) => {
    replacedComponents: ReplacedComponent[];
    components: BoxComponents;
  };
  generateStairBoxComponents: (boxProps: StairBoxProps, allBoxes?: Box[]) => {
    replacedComponents: ReplacedComponent[];
    components: BoxComponents;
  };
  generatePassageBoxComponents: (boxProps: PassageBoxProps, allBoxes?: Box[]) => {
    replacedComponents: ReplacedComponent[];
    components: BoxComponents;
  };
  getComponents: () => Component[];
  getStairBoxLengths: () => number[];
  getStairBoxWidths: () => number[];
  getAvailableLockAnchorRanges: (pos: [number, number, number]) => LockRange[];
  getComponentLabel: (id: string, languageCode: LANGUAGE_CODE) => string;
  getComponentWeight: (id: string) => number;
  getPlankConfigurations: (props: {
    length: number;
    width: number;
    plankType: SCAFFOLD_PLANKTYPE;
  }) => number[][];
  getConsoleWidths: () => number[];
}

export interface ReplacedComponent {
  id: string;
  replacedById: string;
}

export interface BayBoxProps {
  height: Box["height"];
  depth: Box["depth"];
  width: Box["width"];
  rotation: Box["rotation"];
  options: Box["options"];
  position: Box["position"];
  stair?: boolean;
  id: string;
}
export interface StairBoxProps extends BayBoxProps {}
export interface PassageBoxProps extends BayBoxProps {}

export interface LockRange {
  min: number;
  max: number;
}

export interface ComponentBaseProps {
  id: string;
  supplier: string;
}

export enum LANGUAGE_CODE {
  EN = "en",
  SE = "se",
  NO = "no"
}

interface Component {
  article_id: string;
  label_en: string;
  label_no: string;
  label_se: string;
  length: number;
  material: string | SCAFFOLD_PLANKTYPE;
  weight: number;
  width: number;
}

export default Component;
