import { plus } from "math";
import { Euler, Vector3, Vector3Tuple } from "three";
import config from "./config";
import { round } from "lodash";

const TOLERANCE = 0.001;
const c = new Vector3();
const A_VECTOR = new Vector3();
const B_VECTOR = new Vector3();
const POINT_VECTOR = new Vector3();
export const isPointOnLineLimited = (props: {
  pointA: Vector3;
  pointB: Vector3;
  pointToCheck: Vector3;
}) => {
  const { pointA, pointB, pointToCheck } = props;
  c.set(0, 0, 0);
  A_VECTOR.copy(pointA);
  B_VECTOR.copy(pointB);
  POINT_VECTOR.copy(pointToCheck);
  c.crossVectors(A_VECTOR.sub(POINT_VECTOR), B_VECTOR.sub(pointToCheck));

  const isOnLine = Math.abs(c.length()) < TOLERANCE;
  if (!isOnLine) return false;

  const pointBDir = B_VECTOR.copy(pointB).sub(pointA);
  const targetDir = POINT_VECTOR.sub(pointA);

  const bDirLength = pointBDir.length();
  const tDirLength = targetDir.length();

  if (tDirLength > bDirLength) return false;

  const isSameDirection = targetDir.dot(pointBDir) > 0;
  if (!isSameDirection) return false;

  return true;
};

export const getEndPointFromStartPoint = (props: {
  startPosition: Vector3Tuple;
  length: number;
  rotation: [number, number, number];
}) => {
  const { startPosition, length, rotation } = props;

  return new Vector3(1, 0, 0)
    .applyEuler(new Euler().fromArray(rotation))
    .multiplyScalar(length)
    .add(new Vector3().fromArray(startPosition));
};

export const roundVector = (v: Vector3) => {
  v.x = round(v.x, config.decimals);
  v.y = round(v.y, config.decimals);
  v.z = round(v.z, config.decimals);
  return v;
};

export const getPointInBetweenByPer = (
  pointA: Vector3,
  pointB: Vector3,
  percentage: number
) => {
  let dir = pointB.clone().subP(pointA);
  const len = dir.lengthP();
  dir = dir.normalizeP().multiplyScalarP(len * percentage);
  return pointA.clone().addP(dir);
};

export const addVector3Tuples = (a: Vector3Tuple, b: Vector3Tuple) => {
  return a.map((v, idx) => plus(v, b[idx])) as Vector3Tuple;
};

export const rotateAroundPivot = (props: {
  pivot: Vector3;
  object: Vector3;
  rotation: Euler;
}) => {
  const { pivot, object, rotation } = props;

  const clonedObject = object.clone();
  const pivotToObject = clonedObject.subP(pivot);
  const pivotToObjectRotated = pivotToObject.clone().applyEulerP(rotation);

  return pivotToObjectRotated.add(pivot);
};
