import { StateCreator } from "zustand";
import { CompanySlice } from "./company.types";

const createCompanySlice: StateCreator<CompanySlice, [], [], CompanySlice> = (
  set
) => ({
  companyName: "",
  companyId: "",
  companyUsers: [],
  companyLogoUrl: undefined,
  companyActions: {
    setCompanyName: (name) => set({ companyName: name }),
    setCompanyId: (id) => set({ companyId: id }),
    setCompanyUsers: (users) => set({ companyUsers: users }),
    setCompanyLogoUrl: (url) => set({ companyLogoUrl: url })
  }
});

export default createCompanySlice;
