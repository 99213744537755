import { FirebaseError } from "firebase/app";
import { StorageServiceProvider } from "./storage.enums";
import { StorageService } from "./storage.interface";
import {
  StorageError,
  UploadResult,
  UploadTask,
  UploadTaskSnapshot
} from "firebase/storage";
import { initStorageService } from "./storage.utils";
import { IfcProperties, Serializer } from "bim-fragment";
import { IfcModel } from "store/world/ifc/ifc.interface";
import { Blueprint } from "store/world/blueprint/blueprint.types";
import { IfcModelFirestore } from "shared/interfaces/firestore";
import { Timestamp } from "firebase/firestore";

class Storage {
  private storageService: StorageService;

  public constructor(storageService = StorageServiceProvider.FIREBASE) {
    this.storageService = initStorageService(storageService);
  }

  public uploadCompanyLogo = async (props: {
    companyId: string;
    data: ArrayBuffer;
    onSuccess: (res: UploadResult) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { companyId, onSuccess, onError, data } = props;
    const companyLogosPath = `companies/${companyId}/logo.png`;

    this.storageService
      .uploadBytes({
        data,
        path: companyLogosPath
      })
      .then(onSuccess)
      .catch(onError);
  };

  public deleteCompanyLogo = (props: { companyId: string }) => {
    const { companyId } = props;
    const companyLogosPath = `companies/${companyId}/logo.png`;

    return this.storageService.deleteObject({
      path: companyLogosPath
    });
  };

  public getCompanyLogoDownloadUrl = (props: {
    companyId: string;
    onSuccess: (url: string) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { companyId, onSuccess, onError } = props;
    const companyLogosPath = `companies/${companyId}/logo.png`;

    this.storageService.getDownloadUrl({
      path: companyLogosPath,
      onSuccess,
      onError
    });
  };

  public createUploadDataJobs = (
    uploadDataJobs: {
      id?: string;
      data: ArrayBuffer | Blob | File;
      path: string;
      onSuccess?: (res: UploadTaskSnapshot, link: string) => void;
      onError?: (error: StorageError) => void;
    }[]
  ) => {
    const uploadJobs = uploadDataJobs.map((job) => {
      const { id, data, path, onSuccess, onError } = job;
      const task = this.storageService.uploadBytesResumable({ data, path, id });
      if (onSuccess) task.uploadTask.then((res) => onSuccess(res, task.link));
      if (onError) task.uploadTask.catch(onError);
      return task;
    });

    return uploadJobs;
  };

  public createUploadBlueprintJob = (props: {
    blueprint: Blueprint;
    companyId: string;
    projectId?: string;
    scaffoldId: string;
    userId: string;
    onSuccess?: (props: {
      res: UploadTaskSnapshot;
      link: string;
      id: string;
    }) => void;
    onError?: (error: StorageError) => void;
  }) => {
    const {
      blueprint,
      companyId,
      userId,
      projectId,
      scaffoldId,
      onError,
      onSuccess
    } = props;

    const commonPath = `companies/${companyId}/users/${userId}${projectId ? `/projects/${projectId}` : ""}/scaffolds/${scaffoldId}/`;

    const blueprintPromisWithBlob = fetch(blueprint.imageUrl)
      .then((r) => r.blob())
      .then((blob) => ({
        fileName: `blueprint_${blueprint.id}`,
        fileType: "jpg",
        id: blueprint.id,
        blob
      }));

    return blueprintPromisWithBlob.then((blueprintWithBlob) => {
      const { fileName, fileType, blob, id } = blueprintWithBlob;

      const blueprintFilePath = `${commonPath}/${fileName}.${fileType}`;
      const uploadJob = this.createUploadDataJobs([
        {
          data: blob,
          path: blueprintFilePath,
          ...(onSuccess && {
            onSuccess: (res, link) => onSuccess({ res, link, id })
          }),
          onError
        }
      ]);

      return { id, jobs: uploadJob };
    });
  };

  public createUploadBlueprintJobs = (props: {
    blueprints: Blueprint[];
    companyId: string;
    projectId?: string;
    scaffoldId: string;
    userId: string;
    onSuccess?: (props: {
      res: UploadTaskSnapshot;
      link: string;
      id: string;
    }) => void;
    onError?: (error: StorageError) => void;
  }) => {
    const {
      blueprints,
      companyId,
      userId,
      projectId,
      scaffoldId,
      onError,
      onSuccess
    } = props;

    const blueprintJobs = Promise.all(
      blueprints.map((blueprint) => {
        return this.createUploadBlueprintJob({
          blueprint,
          companyId,
          projectId,
          scaffoldId,
          userId,
          onSuccess,
          onError
        });
      })
    );

    return blueprintJobs;
  };

  public createUploadIfcModelJobs = (props: {
    companyId: string;
    models: IfcModel[];
    projectId?: string;
    scaffoldId: string;
    userId: string;
    properties?: IfcProperties;
    onFragmentSuccess?: (props: {
      res: UploadTaskSnapshot;
      link: string;
      modelId: string;
    }) => void;
    onPropertiesSuccess?: (props: {
      res: UploadTaskSnapshot;
      link: string;
      modelId: string;
    }) => void;
    onError?: (error: StorageError) => void;
  }) => {
    const {
      companyId,
      userId,
      projectId,
      scaffoldId,
      models,
      properties,
      onFragmentSuccess,
      onPropertiesSuccess,
      onError
    } = props;
    const commonPath = `companies/${companyId}/users/${userId}${projectId ? `/projects/${projectId}` : ""}/scaffolds/${scaffoldId}/`;
    const fragSerializer = new Serializer();
    const uploadJobs: {
      id: string;
      jobs: {
        id: string;
        link: string;
        uploadTask: UploadTask;
      }[];
      ifcModelFirestore: IfcModelFirestore;
    }[] = [];

    models.forEach((model) => {
      const { fragments, ...rest } = model;

      const fragmentsBinary = fragSerializer.export(fragments);
      const fragmentsFile = new File(
        [fragmentsBinary],
        `ifc-fragment_${model.id}.frag`
      );
      const fragmentsFilePath = `${commonPath}/ifc-fragment_${model.id}.frag`;
      const propertiesString = JSON.stringify(
        properties ? properties : fragments.properties
      );
      const propertiesFile = new File(
        [propertiesString],
        `ifc-properties_${model.id}.json`
      );
      const propertiesFilePath = `${commonPath}/ifc-properties_${model.id}.json`;

      const ifcJobs = this.createUploadDataJobs([
        {
          data: fragmentsFile,
          path: fragmentsFilePath,
          ...(onFragmentSuccess && {
            onSuccess: (res, link) =>
              onFragmentSuccess({ res, link, modelId: model.id })
          }),
          onError
        },
        {
          data: propertiesFile,
          path: propertiesFilePath,
          ...(onPropertiesSuccess && {
            onSuccess: (res, link) =>
              onPropertiesSuccess({ res, link, modelId: model.id })
          }),
          onError
        }
      ]);

      const ifcModelFirestore: IfcModelFirestore = {
        ...rest,
        fragmentsLink: "",
        propertiesLink: "",
        lastUpdatedBlob: Timestamp.now()
      };

      uploadJobs.push({ id: model.id, jobs: ifcJobs, ifcModelFirestore });
    });
    return uploadJobs;
  };

  public downloadIfcFile = (props: { path: string }) => {
    return this.storageService.downloadFileBlob(props);
  };

  public deleteIFcFile = (props: {
    fragmentsPath: string;
    propertiesPath: string;
  }) => {
    const fragProm = this.storageService.deleteObject({
      path: props.fragmentsPath
    });
    const propProm = this.storageService.deleteObject({
      path: props.propertiesPath
    });

    return [fragProm, propProm];
  };

  public deleteIFcFiles = (
    ifcFragAndPropPaths: {
      fragmentsPath: string;
      propertiesPath: string;
    }[]
  ) => {
    return ifcFragAndPropPaths.map((paths) => this.deleteIFcFile(paths)).flat();
  };

  public downloadBlueprintFiles = (props: { paths: string[] }) => {
    const { paths } = props;
    const downloadPromises = paths.map((p) =>
      this.storageService.downloadFileBlob({ path: p })
    );

    return Promise.all(downloadPromises);
  };
  public downloadBlueprintFile = (props: { path: string }) => {
    const { path } = props;
    return this.storageService.downloadFileBlob({ path });
  };

  public deleteBlueprintFiles = (paths: string[]) => {
    return paths.map((p) => this.storageService.deleteObject({ path: p }));
  };
}

/** Default Storage object */
const storage = new Storage();

export { storage };
