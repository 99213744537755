import { ReactElement, useEffect } from "react";
import SnackbarInfoProps from "./SnackbarInfo.types";
import {
  useSnackbarActions,
  useSnackbarOpen,
  useSnackbarText,
  useSnackbarType
} from "store/selectors/snackbar";
import { styled, useTheme } from "@mui/material";
import {
  SnackbarProvider,
  enqueueSnackbar,
  MaterialDesignContent,
  closeSnackbar
} from "notistack";
import IconButton from "ui/core/IconButton";
import { Close } from "@mui/icons-material";
import "./deps/style.css";

const SnackbarInfo = (props: SnackbarInfoProps): ReactElement => {
  const open = useSnackbarOpen();
  const text = useSnackbarText();
  const type = useSnackbarType();
  const { setSnackbarOpen } = useSnackbarActions();

  useEffect(() => {
    if (open && text && type) {
      enqueueSnackbar(text, { variant: type });
      setSnackbarOpen(false);
    }
  }, [open, text, type, setSnackbarOpen]);

  const palette = useTheme().palette;

  const StyledMuiContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: palette.success.main,
      color: palette.success.contrastText
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: palette.warning.main,
      color: palette.warning.contrastText
    },
    "&.notistack-MuiContent-info": {
      backgroundColor: palette.info.main,
      color: palette.info.contrastText
    }
  }));

  return (
    <SnackbarProvider
      autoHideDuration={6000}
      hideIconVariant
      Components={{
        success: StyledMuiContent,
        error: StyledMuiContent,
        warning: StyledMuiContent,
        info: StyledMuiContent
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={(id) => (
        <IconButton
          color="inherit"
          size="small"
          onClick={() => closeSnackbar(id)}
        >
          <Close />
        </IconButton>
      )}
    />
  );
};

export default SnackbarInfo;
