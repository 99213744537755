import { StateCreator } from "zustand";
import { SnackbarSlice } from "./snackbar.types";

const createSnackbarSlice: StateCreator<
  SnackbarSlice,
  [],
  [],
  SnackbarSlice
> = (set) => ({
  snackbarChildren: null,
  snackbarDuration: 5000,
  // snackbarHorizontal: "right",
  snackbarOnClose: () => {},
  snackbarOpen: false,
  snackbarText: "",
  snackbarType: "info",
  // snackbarVertical: "bottom",
  snackbarActions: {
    setSnackbarChildren: (snackbarChildren) => set({ snackbarChildren }),
    setSnackbarDuration: (snackbarDuration) => set({ snackbarDuration }),
    // setSnackbarHorizontal: (snackbarHorizontal) => set({ snackbarHorizontal }),
    setSnackbarOnClose: (snackbarOnClose) => set({ snackbarOnClose }),
    setSnackbarOpen: (snackbarOpen) => set({ snackbarOpen }),
    // setSnackbarText: (snackbarText) => set({ snackbarText }),
    // setSnackbarType: (snackbarType) => set({ snackbarType }),
    // setSnackbarVertical: (snackbarVertical) => set({ snackbarVertical }),
    setSnackbar: ({ text, type }) =>
      set({ snackbarText: text, snackbarType: type, snackbarOpen: true })
  }
});

export default createSnackbarSlice;
