import { ReactElement } from "react";
import ButtonProps from "./Button.types";
import { Button as MUIButton, Stack } from "@mui/material";
import Tooltip from "../Tooltip";

const Button = (props: ButtonProps): ReactElement => {
  const {
    autoFocus,
    children,
    color = "inherit",
    disabled,
    endIcon,
    fullWidth,
    onClick,
    startIcon,
    sx,
    size = "medium",
    tooltipPlacement = "bottom",
    tooltipText = "",
    variant = "contained"
  } = props;

  return (
    <Tooltip text={tooltipText} placement={tooltipPlacement}>
      <Stack width={fullWidth ? "100%" : undefined}>
        <MUIButton
          autoFocus={autoFocus}
          color={color}
          disabled={disabled}
          endIcon={endIcon}
          fullWidth={fullWidth}
          onClick={onClick}
          startIcon={startIcon}
          variant={variant}
          sx={{
            ...sx,
            borderColor: color === "inherit" ? "text.icon" : undefined,
            "&:hover": {
              borderColor: color === "inherit" ? "text.primary" : undefined
            }
          }}
          size={size}
        >
          {children}
        </MUIButton>
      </Stack>
    </Tooltip>
  );
};

export default Button;
