import { useMediaQuery } from "@mui/material";
import Theme from "../Theme";

const Breakpoints = () => {
  const belowSmallScreen = useMediaQuery(Theme({}).breakpoints.down("sm"));
  const belowMediumScreen = useMediaQuery(Theme({}).breakpoints.down("md"));
  const belowLargeScreen = useMediaQuery(Theme({}).breakpoints.down("lg"));

  return {
    belowSmallScreen,
    belowMediumScreen,
    belowLargeScreen
  };
};

export default Breakpoints;
