import { FemRequestProps, RunFemProps } from "shared/excluded/fem";
import { ComponentType, GraphData } from "world/core/Standard/Standard.types";

const isStartNodeGrounded = (node: string, data: GraphData) => {
  return node === data.startPosition.toString() && data.startGrounded;
};

const isEndNodeGrounded = (node: string, data: GraphData) => {
  return node === data.endPosition.toString() && data.endGrounded;
};

export const genFemRequestProps: (props: RunFemProps) => FemRequestProps = (
  props
) => {
  const {
    graph,
    anchorStiffness = 2000,
    groundStiffness = 2000,
    ledgerHingeStiffness_x = 0,
    ledgerHingeStiffness_y = 5,
    ledgerHingeStiffness_z = 70,
    terrainType,
    windReffSpeed,
    scaffoldSupplier,
    fixedGroundSupport = false,
    winddirection = [0, 0, 1],
    maxHeight = 8
  } = props;

  const nodes = graph
    .filterNodes((_, attributes) => attributes.type === "component")
    .map((node, idx) => {
      const position = graph.getNodeAttribute(node, "position");
      const component = graph.getNodeAttribute(
        node,
        "component"
      ) as GraphData[];
      const isGround =
        component.length === 1 &&
        (isStartNodeGrounded(node, component[0]) ||
          isEndNodeGrounded(node, component[0]));

      return {
        id: node,
        position,
        idx: idx + 1,
        isGround
      };
    });

  const elements = graph
    .filterEdges((_, attributes) => {
      const data = attributes as { type: string; component: GraphData };
      return (
        data.type === "component" &&
        [
          ComponentType.LEDGER,
          ComponentType.STANDARD,
          ComponentType.DIAGONAL_BRACE,
          ComponentType.BASE_PLATE,
          ComponentType.BASE_COLLAR,
          ComponentType.ANCHOR,
          ComponentType.CONSOLE,
          ComponentType.PLANK
        ].includes(data.component.type)
      );
    })
    .map((edge, idx) => {
      const source = graph.source(edge);
      const target = graph.target(edge);

      const component = graph.getEdgeAttribute(edge, "component");

      return {
        id: edge,
        node1: nodes.findIndex((node) => node.id === source) + 1,
        node2: nodes.findIndex((node) => node.id === target) + 1,
        idx: idx + 1,
        ...(component.metadata.elementVector && {
          elementVector: component.metadata.elementVector
        }),
        type: component.type
      };
    });

  const requestProps: FemRequestProps = {
    anchornodes: [],
    anchorStiffness,
    diagonals: elements
      .filter((element) => element.type === ComponentType.DIAGONAL_BRACE)
      .map((e) => e.idx),
    elements: elements.map((element) => [
      element.idx,
      element.node1,
      element.node2
    ]),
    groundednodes: nodes.filter((n) => n.isGround).map((n) => n.idx),
    groundStiffness,
    ledgers: elements
      .filter((element) =>
        [
          ComponentType.LEDGER,
          ComponentType.ANCHOR,
          ComponentType.CONSOLE,
          ComponentType.PLANK
        ].includes(element.type)
      )
      .map((e) => e.idx),
    nodes: nodes.map((node) => [
      node.idx,
      node.position[0],
      node.position[1],
      node.position[2]
    ]),
    nZ: elements.map((element) => element.elementVector),
    standards: elements
      .filter((element) =>
        [
          ComponentType.STANDARD,
          ComponentType.BASE_COLLAR,
          ComponentType.BASE_PLATE
        ].includes(element.type)
      )
      .map((e) => e.idx),
    fixedGroundSupport,
    ledgerHingeStiffness_x,
    ledgerHingeStiffness_y,
    ledgerHingeStiffness_z,
    maxHeight,
    scaffoldSupplier,
    terrainType,
    winddirection,
    windReffSpeed
  };

  console.log("props to fem", requestProps);
  return requestProps;
};
