import { genFemRequestProps } from "math/graph";
import { ApiService, ApiServiceProvider } from "./api.types";
import { initApiService } from "./api.utils";
import { RunFemProps } from "shared/excluded/fem";
import {
  CreateCompanyRequestProps,
  CreateUserRequestProps,
  DuplicateScaffoldRequestProps
} from "shared/interfaces/api";

class Api {
  /** The authService holds the abstracted authentication service */
  public apiService: ApiService;

  /** The authService can be initialized with any AuthenticationServiceProvider but default is .FIREBASE */
  public constructor(serviceProvider = ApiServiceProvider.FIREBASE) {
    /** A helper function is used to initialize the authServive  */
    this.apiService = initApiService(serviceProvider);
  }

  public createCompany = (company: CreateCompanyRequestProps) => {
    return this.apiService.createCompany(company);
  };

  public fetchMapboxAPIKey = (token: string) => {
    return this.apiService.fetchMapboxAPIKey(token);
  };

  public createUser = (user: Omit<CreateUserRequestProps, "creatorEmail">) => {
    return this.apiService.createUser(user);
  };

  public sendPasswordResetLink = (email: string) => {
    return this.apiService.sendPasswordResetLink(email);
  };

  public generateResetPasswordLink = (email: string, uid: string) => {
    return this.apiService.generateResetPasswordLink(email, uid);
  };

  public deactivateUser = (id: string) => {
    return this.apiService.deactivateUser(id);
  };

  public activateUser = (id: string) => {
    return this.apiService.activateUser(id);
  };

  public getUsersData = (ids: string[]) => {
    return this.apiService.getUsersData(ids);
  };

  public duplicateScaffold = (props: DuplicateScaffoldRequestProps) => {
    return this.apiService.duplicateScaffold(props);
  };

  public runFem = (construction: RunFemProps) => {
    const requestProps = genFemRequestProps(construction);
    return this.apiService.runFem(requestProps).then((res) => res?.json());
  };

  public updateScaffoldSnapshot = (id: string) => {
    return this.apiService.updateScaffoldSnapshot(id);
  };
}

/** Initialize a Api object */
const api = new Api();

export { api };
export default Api;
