import { FemRequestProps } from "shared/excluded/fem";
import {
  CreateCompanyRequestProps,
  CreateUserRequestProps,
  DuplicateScaffoldRequestProps
} from "shared/interfaces/api";

export interface ApiService {
  createCompany: (
    company: CreateCompanyRequestProps
  ) => Promise<Response | undefined>;
  createUser: (
    company: Omit<CreateUserRequestProps, "creatorEmail">
  ) => Promise<Response | undefined>;
  generateResetPasswordLink: (
    email: string,
    uid: string
  ) => Promise<Response | undefined>;
  deactivateUser: (id: string) => Promise<Response | undefined>;
  activateUser: (id: string) => Promise<Response | undefined>;
  fetchMapboxAPIKey: (token: string) => Promise<Response | undefined>;
  getUsersData: (ids: string[]) => Promise<Response | undefined>;
  duplicateScaffold: (
    props: DuplicateScaffoldRequestProps
  ) => Promise<Response | undefined>;
  runFem: (props: FemRequestProps) => Promise<Response | undefined>;
  updateScaffoldSnapshot: (id: string) => Promise<Response | undefined>;
  sendPasswordResetLink: (email: string) => Promise<Response | undefined>;
}

export interface GetUsersAuthDataResponse {
  [id: string]: {
    isEmailVerified: boolean;
    hasSignedIn: boolean;
    lastSignInTime: string;
  };
}

export enum ApiServiceProvider {
  FIREBASE = "firebase"
}
