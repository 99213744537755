import { divide, double, half, minus, plus, strip, times } from "math";
import { roundVector } from "math/vectors";
import { Euler, Quaternion, Vector3 } from "three";

const ROUND = true;
declare module "three" {
  interface Vector3 {
    addP(v: Vector3): Vector3;
    applyEulerP(e: Euler): Vector3;
    applyAxisAngleP(axis: Vector3, angle: number): Vector3;
    applyQuaternionP(q: Quaternion): Vector3;
    crossVectorsP(a: Vector3, b: Vector3): Vector3;
    distanceToP(v: Vector3): number;
    distanceToSquaredP(v: Vector3): number;
    divideP(v: Vector3): Vector3;
    divideScalarP(s: number): Vector3;
    lengthP(): number;
    multiplyP(v: Vector3): Vector3;
    multiplyScalarP(s: number): Vector3;
    subP(v: Vector3): Vector3;
    normalizeP(): Vector3;
  }

  interface Quaternion {
    setFromEulerP(e: Euler): Quaternion;
    setFromAxisAngle(axis: Vector3, angle: number): Quaternion;
  }
}

Vector3.prototype.addP = function (v: Vector3) {
  this.x = plus(this.x, v.x);
  this.y = plus(this.y, v.y);
  this.z = plus(this.z, v.z);

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.subP = function (v: Vector3) {
  this.x = minus(this.x, v.x);
  this.y = minus(this.y, v.y);
  this.z = minus(this.z, v.z);

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.multiplyP = function (v: Vector3) {
  this.x = times(this.x, v.x);
  this.y = times(this.y, v.y);
  this.z = times(this.z, v.z);

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.multiplyScalarP = function (s: number) {
  this.x = times(this.x, s);
  this.y = times(this.y, s);
  this.z = times(this.z, s);

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.divideP = function (v: Vector3) {
  this.x = divide(this.x, v.x);
  this.y = divide(this.y, v.y);
  this.z = divide(this.z, v.z);

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.applyEulerP = function (e: Euler) {
  this.applyQuaternionP(new Quaternion().setFromEulerP(e));

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.applyAxisAngleP = function (axis: Vector3, angle: number) {
  this.applyQuaternionP(new Quaternion().setFromAxisAngle(axis, angle));

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.applyQuaternionP = function (q: Quaternion) {
  // quaternion q is assumed to have unit length
  const vx = this.x,
    vy = this.y,
    vz = this.z;
  const qx = q.x,
    qy = q.y,
    qz = q.z,
    qw = q.w;

  // t = 2 * cross( q.xyz, v );
  const tx = double(minus(times(qy, vz), times(qz, vy)));
  const ty = double(minus(times(qz, vx), times(qx, vz)));
  const tz = double(minus(times(qx, vy), times(qy, vx)));

  // v + q.w * t + cross( q.xyz, t );
  /** NOTE:
   * - Added strip function since I cant find why it is still not precise
   */
  this.x = strip(minus(plus(vx, times(qw, tx), times(qy, tz)), times(qz, ty)));
  this.y = strip(minus(plus(vy, times(qw, ty), times(qz, tx)), times(qx, tz)));
  this.z = strip(minus(plus(vz, times(qw, tz), times(qx, ty)), times(qy, tx)));

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.lengthP = function () {
  return strip(
    Math.sqrt(
      plus(times(this.x, this.x), times(this.y, this.y), times(this.z, this.z))
    )
  );
};

Vector3.prototype.crossVectorsP = function (a: Vector3, b: Vector3) {
  const ax = a.x,
    ay = a.y,
    az = a.z;
  const bx = b.x,
    by = b.y,
    bz = b.z;

  this.x = minus(times(ay, bz), times(az, by));
  this.y = minus(times(az, bx), times(ax, bz));
  this.z = minus(times(ax, by), times(ay, bx));

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.distanceToP = function (v: Vector3) {
  return strip(Math.sqrt(this.distanceToSquaredP(v)));
};

Vector3.prototype.distanceToSquaredP = function (v: Vector3) {
  return strip(
    plus(
      times(minus(this.x, v.x), minus(this.x, v.x)),
      times(minus(this.y, v.y), minus(this.y, v.y)),
      times(minus(this.z, v.z), minus(this.z, v.z))
    )
  );
};

Vector3.prototype.divideScalarP = function (s: number) {
  this.multiplyScalarP(divide(1, s));

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Vector3.prototype.normalizeP = function () {
  this.divideScalarP(this.lengthP() || 1);

  if (ROUND) {
    return roundVector(this);
  }

  return this;
};

Quaternion.prototype.setFromEulerP = function (e: Euler) {
  const c1 = Math.cos(half(e.x));
  const c2 = Math.cos(half(e.y));
  const c3 = Math.cos(half(e.z));
  const s1 = Math.sin(half(e.x));
  const s2 = Math.sin(half(e.y));
  const s3 = Math.sin(half(e.z));

  const order = e.order;

  if (order === "XYZ") {
    this.x = plus(times(s1, c2, c3), times(c1, s2, s3));
    this.y = minus(times(c1, s2, c3), times(s1, c2, s3));
    this.z = plus(times(c1, c2, s3), times(s1, s2, c3));
    this.w = minus(times(c1, c2, c3), times(s1, s2, s3));
  } else if (order === "YXZ") {
    this.x = plus(times(s1, c2, c3), times(c1, s2, s3));
    this.y = minus(times(c1, s2, c3), times(s1, c2, s3));
    this.z = minus(times(c1, c2, s3), times(s1, s2, c3));
    this.w = plus(times(c1, c2, c3), times(s1, s2, s3));
  } else if (order === "ZXY") {
    this.x = minus(times(s1, c2, c3), times(c1, s2, s3));
    this.y = plus(times(c1, s2, c3), times(s1, c2, s3));
    this.z = plus(times(c1, c2, s3), times(s1, s2, c3));
    this.w = minus(times(c1, c2, c3), times(s1, s2, s3));
  } else if (order === "ZYX") {
    this.x = minus(times(s1, c2, c3), times(c1, s2, s3));
    this.y = plus(times(c1, s2, c3), times(s1, c2, s3));
    this.z = minus(times(c1, c2, s3), times(s1, s2, c3));
    this.w = plus(times(c1, c2, c3), times(s1, s2, s3));
  } else if (order === "YZX") {
    this.x = plus(times(s1, c2, c3), times(c1, s2, s3));
    this.y = plus(times(c1, s2, c3), times(s1, c2, s3));
    this.z = minus(times(c1, c2, s3), times(s1, s2, c3));
    this.w = minus(times(c1, c2, c3), times(s1, s2, s3));
  } else if (order === "XZY") {
    this.x = minus(times(s1, c2, c3), times(c1, s2, s3));
    this.y = minus(times(c1, s2, c3), times(s1, c2, s3));
    this.z = plus(times(c1, c2, s3), times(s1, s2, c3));
    this.w = plus(times(c1, c2, c3), times(s1, s2, s3));
  }

  return this;
};

Quaternion.prototype.setFromAxisAngle = function (
  axis: Vector3,
  angle: number
) {
  const halfAngle = half(angle);
  const s = Math.sin(halfAngle);

  this.x = times(axis.x, s);
  this.y = times(axis.y, s);
  this.z = times(axis.z, s);
  this.w = Math.cos(halfAngle);

  return this;
};
