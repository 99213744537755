export enum WORLD_TOOL_MODE {
  BLUEPRINT = "blueprint",
  BOX = "box",
  BOX_BAY = "box_bay",
  BOX_PASSAGE = "box_passage",
  BOX_AUTO = "box_auto",
  BOX_STAIR = "box_stair",
  COMMUNICATION = "communication",
  COMMUNICATION_COMMENT = "communication_comment",
  COMMUNICATION_RULER = "communication_ruler",
  GROUND = "ground",
  GROUND_FLAT = "ground_flat",
  GROUND_SLOPE = "ground_slope",
  HOUSE = "house",
  HOUSE_CYLINDER = "house_cylinder",
  HOUSE_IFC = "house_ifc",
  HOUSE_MAPS = "house_maps",
  HOUSE_POLYGON = "house_polygon",
  HOUSE_RECTANGLE = "house_rectangle",
  HOUSE_ADD_POINT = "house_add_point",
  HOUSE_EDIT_POINT = "house_edit_point"
}

export enum WORLD_RENDER_MODE {
  BOX = "box",
  REAL = "real"
}
