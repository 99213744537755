export enum APP_MODES {
  DRAW = "DRAW",
  VIEW = "VIEW",
  SHARED = "SHARED"
}

export enum APP_VARIANTS {
  TRS = "TRS",
  SCAFFOLD = "SCAFFOLD"
}
