import { ReactElement, useEffect, useMemo } from "react";
import TrialInfoProps from "./TrialInfo.types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserAccountType, useUserExpires } from "store/selectors/user";
import { USER_ACCOUNTTYPE } from "shared/enums/user";
import Button from "ui/core/Button";
import Snackbar from "ui/module/Snackbar";

const TrialInfo = (props: TrialInfoProps): ReactElement => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const userAccountType = useUserAccountType();

  const userExpires = useUserExpires();

  const daysToExpiration = useMemo(() => {
    let daysToExpiration = null;

    if (userAccountType !== USER_ACCOUNTTYPE.TRIAL || userExpires === null)
      return daysToExpiration;

    let today: number = new Date().getTime();
    let expirationDate: number = userExpires.getTime();

    daysToExpiration = Math.ceil((expirationDate - today) / (1000 * 3600 * 24));

    return daysToExpiration;
  }, [userAccountType, userExpires]);

  const isExpired = useMemo(
    () => daysToExpiration !== null && daysToExpiration < 1,
    [daysToExpiration]
  );

  const bannerText = useMemo(() => {
    if (userAccountType !== USER_ACCOUNTTYPE.TRIAL || daysToExpiration === null)
      return "";

    if (isExpired) return t("copy.trial.expired");

    return `${t("copy.trial.daysLeft", { days: daysToExpiration })}   `;
  }, [daysToExpiration, t, userAccountType, isExpired]);

  const buttonText = useMemo(() => {
    if (isExpired) return t("actions.contactUs");

    return t("actions.upgrade");
  }, [t, isExpired]);

  const handleOnClickButton = () => {
    window.open("https://www.scaffcalc.com/support", "_blank");
  };

  useEffect(() => {
    if (isExpired) {
      navigate("/home");
    }
  }, [isExpired, navigate]);

  return (
    <>
      {userAccountType === USER_ACCOUNTTYPE.TRIAL && (
        <Snackbar
          open={true}
          message={bannerText}
          type={"secondary"}
          autoHideDuration={null}
          button={
            <Button
              onClick={handleOnClickButton}
              color={"inherit"}
              variant="text"
            >
              {buttonText}
            </Button>
          }
          sx={{
            position: "relative",
            bottom: "0px !important",
            right: "0px !important"
          }}
        />
      )}
    </>
  );
};

export default TrialInfo;
