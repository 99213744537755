import { divide, half } from "math";
import { halfPi } from "math/constants";
import { Point, Polygon } from "shared/interfaces/firestore";
import { Box3, Euler, Vector2, Vector3 } from "three";
import { genId } from "math/generators";

export const specialInflatePolygonPositions = (props: {
  closedPositions: Vector3[];
  distance: number;
}) => {
  const { closedPositions, distance } = props;
  const newPositions: Vector3[] = [];

  closedPositions.forEach((currentPosition, idx) => {
    const prevPosition = closedPositions[idx - 1];
    const nextPosition = closedPositions[idx + 1];

    if (prevPosition && nextPosition) {
      const newPosition = createNewInflatedPosition({
        prevPosition,
        currentPosition,
        nextPosition,
        distance
      });

      newPositions.push(newPosition);
    }
  });

  /** Calculate first point */
  if (closedPositions.length < 4) return newPositions;
  const currentPosition = closedPositions[0];
  const prevPosition = closedPositions[closedPositions.length - 2];
  const nextPosition = closedPositions[1];

  const newFirstPointPosition = createNewInflatedPosition({
    prevPosition,
    currentPosition,
    nextPosition,
    distance
  });
  newPositions.unshift(newFirstPointPosition);

  return [...newPositions, newFirstPointPosition];
};

const createNewInflatedPosition = (props: {
  prevPosition: Vector3;
  currentPosition: Vector3;
  nextPosition: Vector3;
  distance: number;
}) => {
  const { prevPosition, currentPosition, nextPosition, distance } = props;

  const { endPosition: prevEndPosition } = createOffsetedVectors({
    startPosition: prevPosition,
    endPosition: currentPosition,
    offset: distance
  });

  const { startPosition: nextStartPosition } = createOffsetedVectors({
    startPosition: currentPosition,
    endPosition: nextPosition,
    offset: distance
  });

  /** Calculate where endPosition and startPosition meets */
  const currentPosToNewEnd = prevEndPosition.clone().sub(currentPosition);
  const currentPosToNewStart = nextStartPosition.clone().sub(currentPosition);

  const lineVectorLength = currentPosToNewEnd.length();

  const angle = calculateDirectedAngleBetweenVectors({
    startPointVector: currentPosToNewStart,
    middlePointVector: new Vector3(),
    endPointVector: currentPosToNewEnd
  });

  const newLineVectorLength = divide(lineVectorLength, Math.cos(half(angle)));
  const newLineVector = currentPosToNewEnd
    .clone()
    .applyEuler(new Euler(0, -half(angle), 0))
    .normalize()
    .multiplyScalar(newLineVectorLength);

  const newPosition = currentPosition.clone().add(newLineVector);

  return newPosition;
};

export const createOffsetedVectors = (props: {
  startPosition: Vector3;
  endPosition: Vector3;
  offset: number;
}): { startPosition: Vector3; endPosition: Vector3 } => {
  const { startPosition, endPosition, offset } = props;
  const newStartPosition = startPosition.clone();
  const newEndPosition = endPosition.clone();

  const endBaseVector = newEndPosition.clone().sub(newStartPosition);

  const normalisedEndBaseVector = endBaseVector.clone().normalize();
  const perpendicularVector = normalisedEndBaseVector
    .clone()
    .cross(new Vector3(0, 1, 0));

  const halfDistanceOffset = perpendicularVector.clone().multiplyScalar(offset);

  newStartPosition.sub(halfDistanceOffset);
  newEndPosition.sub(halfDistanceOffset);

  return { startPosition: newStartPosition, endPosition: newEndPosition };
};

export const calculateAngleBetweenVectors = (props: {
  startPointVector: Vector3;
  endPointVector: Vector3;
  startRotationY?: number;
}) => {
  const { startPointVector, endPointVector, startRotationY } = props;

  let rawAngle = Math.atan2(
    startPointVector.x - endPointVector.x,
    startPointVector.z - endPointVector.z
  );

  let angle = rawAngle;
  if (rawAngle < 0) {
    angle = rawAngle + 2 * Math.PI;
  }

  let newAngle = angle;
  if (startRotationY) {
    newAngle = angle - startRotationY;
  }

  let finalAngle = newAngle;
  if (newAngle < 0) {
    finalAngle = newAngle + 2 * Math.PI;
  }

  return finalAngle;
};

export const calculateForwardDragDistance = (props: {
  startPoint: Vector2;
  endPoint: Vector2;
  startRotationY: number;
}) => {
  const { startPoint, endPoint, startRotationY } = props;

  const dragVector = endPoint.clone().sub(startPoint);

  /** Undo the startRotationY rotation */
  const dragVectorRotated = dragVector
    .clone()
    .rotateAround(new Vector2(), -startRotationY);

  if (dragVectorRotated.x < 0) return 0;

  return dragVectorRotated.x;
};

export const calculateBackwardDragDistance = (props: {
  startPoint: Vector2;
  endPoint: Vector2;
  startRotationY: number;
}) => {
  const { startPoint, endPoint, startRotationY } = props;

  const dragVector = endPoint.clone().sub(startPoint);

  /** Undo the startRotationY rotation */
  const dragVectorRotated = dragVector
    .clone()
    .rotateAround(new Vector2(), -startRotationY);

  if (dragVectorRotated.x > 0) return 0;

  return Math.abs(dragVectorRotated.x);
};

export const calculateDirectedAngleBetweenVectors = (props: {
  startPointVector: Vector3;
  middlePointVector: Vector3;
  endPointVector: Vector3;
  skipPositive?: boolean;
}) => {
  const { startPointVector, middlePointVector, endPointVector, skipPositive } =
    props;

  const startToMiddleVector = startPointVector.clone().sub(middlePointVector);
  const endToMiddleVector = endPointVector.clone().sub(middlePointVector);

  let angle =
    Math.atan2(startToMiddleVector.z, startToMiddleVector.x) -
    Math.atan2(endToMiddleVector.z, endToMiddleVector.x);

  if (skipPositive) return angle;

  if (angle < 0) angle += 2 * Math.PI;

  return angle;
};

export const createVector3FromPoint = (point: Vector2, yPos?: number) => {
  const y = yPos || 0;
  return new Vector3(point.x, y, point.y);
};

export const getPolygonSnapToSide = (props: {
  points: Polygon["points"];
  height: Polygon["height"];
  position: Polygon["position"];
  rotation: Polygon["rotation"];
}) => {
  const boxes: {
    startPoint: Point;
    endPoint: Point;
    position: Vector3;
    length: number;
    width: number;
    rotation: Euler;
    height: number;
    polygonPosition: Vector3;
    polygonRotation: Euler;
  }[] = [];
  const { points, height, position, rotation } = props;
  points.forEach((point, idx) => {
    const startPoint = idx > 0 ? points[idx - 1] : points[points.length - 1];
    const endPoint = point;

    const { startPosition, endPosition } = createOffsetedVectors({
      startPosition: new Vector3(...startPoint.position).setY(0),
      endPosition: new Vector3(...endPoint.position).setY(0),
      offset: 0.01
    });

    const planeLength = startPosition.clone().sub(endPosition.clone()).length();

    const midPlanePosition = startPosition
      .clone()
      .add(endPosition.clone().sub(startPosition.clone()).multiplyScalar(0.5));

    const endBaseVector = endPosition.clone().sub(startPosition);

    const angle = calculateDirectedAngleBetweenVectors({
      startPointVector: new Vector3(0, 0, 1),
      middlePointVector: new Vector3(0, 0, 0),
      endPointVector: endBaseVector
    });

    boxes.push({
      startPoint,
      length: planeLength,
      width: 2,
      position: midPlanePosition,
      endPoint,
      rotation: new Euler(-Math.PI / 2, 0, angle),
      height,
      polygonPosition: new Vector3(...position),
      polygonRotation: new Euler().fromArray(rotation)
    });
  });

  return boxes;
};

export const getDistanceBetweenPoints = (props: {
  startPosition: Vector3;
  endPosition: Vector3;
}) => {
  const { startPosition, endPosition } = props;

  const planeLength = startPosition.clone().sub(endPosition.clone()).length();

  const midPlanePosition = startPosition
    .clone()
    .add(endPosition.clone().sub(startPosition.clone()).multiplyScalar(0.5));
  const endBaseVector = endPosition.clone().sub(startPosition);

  const angle = calculateDirectedAngleBetweenVectors({
    startPointVector: new Vector3(0, 0, 1),
    middlePointVector: new Vector3(0, 0, 0),
    endPointVector: endBaseVector
  });

  return {
    length: planeLength,
    position: midPlanePosition,
    rotation: new Euler(-halfPi, 0, angle + halfPi)
  };
};

export const getLineLengths = (props: { points: Polygon["points"] }) => {
  const { points } = props;

  const lines: {
    startPoint: Point;
    endPoint: Point;
    position: Vector3;
    length: number;
  }[] = [];

  points.forEach((point, idx) => {
    const startPoint = idx > 0 ? points[idx - 1] : points[points.length - 1];
    const endPoint = point;

    const startPosition = new Vector3(
      startPoint.position[0],
      0,
      startPoint.position[2]
    );
    const endPosition = new Vector3(
      endPoint.position[0],
      0,
      endPoint.position[2]
    );
    const planeLength = startPosition.clone().sub(endPosition.clone()).length();

    const midPlanePosition = startPosition
      .clone()
      .add(endPosition.clone().sub(startPosition.clone()).multiplyScalar(0.5));

    lines.push({
      startPoint,
      length: planeLength,
      position: midPlanePosition,
      endPoint
    });
  });

  return lines;
};

export const getCylinderLineLengths = (props: {
  points: Polygon["points"];
}) => {
  const { points } = props;

  const lines: {
    startPoint: Point;
    endPoint: Point;
    position: Vector3;
    length: number;
  }[] = [];

  const bbox = new Box3().setFromPoints(
    points.map((p) => new Vector3().fromArray(p.position))
  );

  const cornerPoints: Point[] = [
    {
      position: [bbox.min.x, 0, bbox.min.z],
      id: genId()
    },
    {
      position: [bbox.min.x, 0, bbox.max.z],
      id: genId()
    },
    {
      position: [bbox.max.x, 0, bbox.max.z],
      id: genId()
    }
  ];
  const center = bbox.getCenter(new Vector3());

  const lengthX = bbox.max.x - bbox.min.x;
  const lengthZ = bbox.max.z - bbox.min.z;

  lines.push({
    startPoint: cornerPoints[0],
    length: lengthX,
    position: new Vector3(center.x, 0, 0),
    endPoint: cornerPoints[1]
  });

  lines.push({
    startPoint: cornerPoints[1],
    length: lengthZ,
    position: new Vector3(0, 0, center.z),
    endPoint: cornerPoints[2]
  });

  lines.push({
    startPoint: cornerPoints[2],
    length: lengthX,
    position: new Vector3(center.x, 0, -lengthZ),
    endPoint: cornerPoints[0]
  });

  lines.push({
    startPoint: cornerPoints[0],
    length: lengthZ,
    position: new Vector3(-lengthX, 0, center.z),
    endPoint: cornerPoints[2]
  });

  return lines;
};
