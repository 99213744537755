import { StateCreator } from "zustand";
import { UserSlice } from "./user.types";
import { SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import { TRS_SYSTEM } from "shared/enums/trs";

const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (set) => ({
  userEmail: "",
  userFirstName: "",
  userLastName: "",
  userUId: "",
  userAccountType: "",
  userRole: "",
  userExpires: null,
  userAppPreferences: {
    languageCode: "en",
    appearance: "system"
  },
  userScaffoldPreferences: {
    suppliers: [
      SCAFFOLD_SUPPLIER.LAYHER,
      SCAFFOLD_SUPPLIER.HAKI,
      SCAFFOLD_SUPPLIER.PLUSEIGHT
    ],
    defaultSupplier: SCAFFOLD_SUPPLIER.LAYHER,
    trsSuppliers: [TRS_SYSTEM.KEDERXL, TRS_SYSTEM.GIBSON],
    defaultTrsSupplier: TRS_SYSTEM.GIBSON,
    toeBoardColor: "#D65CC4",
    toeBoardText: "",
    clothingColor: "#D65CC4",
    clothingText: "",
  },
  userAchievements: [],
  userAuthorisation: [],
  userActions: {
    setUserEmail: (email) => set({ userEmail: email }),
    setUserFirstName: (name) => set({ userFirstName: name }),
    setUserLastName: (name) => set({ userLastName: name }),
    setUserId: (id) => set({ userUId: id }),
    setUserAppPreferences: (appPreferences) =>
      set({ userAppPreferences: appPreferences }),
    setUserScaffoldPreferences: (scaffoldPreferences) =>
      set({ userScaffoldPreferences: scaffoldPreferences }),
    setUserAchievements: (achievements) => set({ userAchievements: achievements }),
    setUserAccountType: (accountType) => set({ userAccountType: accountType }),
    setUserExpires: (expires) => set({ userExpires: expires }),
    setUserRole: (role) => set({ userRole: role }),
    setUserAuthorisation: (authorisation) =>
      set({ userAuthorisation: authorisation }),
    clearUser: () =>
      set({
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userAuthorisation: []
      })
  }
});

export default createUserSlice;
