import { ReactElement } from "react";
import SnackbarProps from "./Snackbar.types";
import { Snackbar as MUISnackbar, useTheme } from "@mui/material";
import IconButton from "ui/core/IconButton";
import { Close } from "@mui/icons-material";

const Snackbar = (props: SnackbarProps): ReactElement => {
  const { autoHideDuration, button, open, onClose, message, type, sx } = props;

  const palette = useTheme().palette;

  const lightMode = palette.mode === "light";

  return (
    <MUISnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      action={
        <>
          {button}
          {onClose && (
            <IconButton color="inherit" onClick={onClose} size="small">
              <Close />
            </IconButton>
          )}
        </>
      }
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor:
            type === "secondary" && lightMode
              ? "background.paperSecondary"
              : `${type}.main`,
          color:
            type === "secondary" && lightMode
              ? "text.primary"
              : `${type}.contrastText`
        },
        ...sx
      }}
    />
  );
};

export default Snackbar;
