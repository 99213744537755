export enum AnalyticsServiceProvider {
  FIREBASE = "firebase"
}
export enum AppFeatures {
  AUTO_SCAFFOLD = "auto_scaffold",
  MAP_IMPORT = "maps_import",
  IFC_IMPORT = "ifc_import",
  PDF_IMPORT = "pdf_import",
  COMPONENTS_PDF_EXPORT = "components_pdf_export",
  COMPONENTS_CSV_IMPORT = "components_csv_export",
  COMPONENTS_EXCEL_IMPORT = "components_excel_export",
  DIM_PDF_EXPORT = "dim_pdf_export",
  SCAFFOLD_SHARE = "scaffold_share",
  APPEARANCE_SETTINGS = "appearance_settings",
  LANGUAGE_SETTINGS = "language_settings",
  SCAFFOLD_SETTINGS = "scaffold_settings",
  WORLD_MODE = "world_mode",
  COMPONENT_MODIFICATION = "component_modification",
  UNDO = "undo",
  REDO = "redo",
  GROUND = "ground",
  HOUSE_BODY = "house_body",
  ROOF = "roof",
  POLYGON_POINT_MODIFICATION = "polygon_point_modification",
  BOX = "box",
  MEASUREMENT = "measurement",
  SAVE = "save"
}
