const lightPalette = {
  background: {
    default: "hsl(224, 61%,97%)",
    paper: "hsl(224, 45%,95%)",
    world: "hsl(224, 80%, 99%)",
    action: "hsl(219, 70%, 95%)",
    paperSecondary: "hsl(309, 60%, 80%)"
  },
  primary: {
    main: "hsl(204, 100%, 38%)",
    contrastText: "#ffffff"
  },
  primaryVariant: {
    main: "hsl(204, 70%, 60%)",
    dark: "hsl(204, 70%, 51%)",
    light: "hsl(204, 70%, 64%)",
    contrastText: "hsla(219, 38%, 7%, 0.87)"
  },
  secondary: {
    main: "hsl(309, 70%, 42%)",
    contrastText: "#ffffff"
  },
  secondaryVariant: {
    main: "hsl(309, 60%, 60%)",
    dark: "hsl(309, 60%, 51%)",
    light: "hsl(309, 60%, 64%)",
    contrastText: "#ffffff"
  },
  success: {
    main: "hsl(123, 46%, 40%)",
    contrastText: "#ffffff"
  },
  warning: {
    main: "hsl(30, 100%, 50%)",
    contrastText: "#ffffff"
  },
  error: {
    main: "hsl(5, 65%, 51%)",
    contrastText: "#ffffff"
  },
  info: {
    main: "hsl(219, 20%, 40%)",
    contrastText: "#ffffff"
  },
  text: {
    primary: "hsla(219, 38%, 7%, 0.87)",
    secondary: "hsla(219, 38%, 7%, 0.6)",
    disabled: "hsla(219, 38%, 7%, 0.12)",
    icon: "hsla(219, 38%, 7%, 0.5)"
  },
  action: {
    active: "hsla(219, 38%, 7%, 0.8)",
    hover: "hsla(219, 38%, 7%, 0.04)",
    selected: "hsla(219, 38%, 7%, 0.08)",
    disabled: "hsla(219, 38%, 7%, 0.26)",
    disabledBackground: "hsla(219, 38%, 7%, 0.12)",
    focus: "hsla(219, 38%, 7%, 0.12)"
  },
  divider: "hsla(219, 38%, 7%, 0.12)",
  world: {
    axes: {
      x: "hsl(309, 100%, 75%)",
      y: "hsl(240, 100%, 75%)",
      z: "hsl(180, 90%, 60%)"
    },
    box: {
      main: "hsl(204, 80%, 50%)",
      hovered: "hsl(204, 90%, 45%)",
      selected: "hsl(204, 100%, 38%)"
    },
    edges: "hsl(204, 20%, 40%)",
    grid: "hsl(219, 40%, 12%)",
    ground: {
      main: "hsl(123, 10%, 70%)",
      hovered: "hsl(152, 12%, 68%)",
      selected: "hsl(170, 14%, 66%)"
    },
    houseBody: {
      main: "hsl(219, 20%, 70%)",
      hovered: "hsl(215, 25%, 69%)",
      selected: "hsl(212, 30%, 68%)"
    },
    placeholder: {
      main: "hsl(123, 50%, 40%)",
      hovered: "hsl(123, 55%, 36%)"
    },
    realComponent: {
      main: "hsl(204, 0%, 50%)",
      hovered: "hsl(204, 20%, 50%)",
      selected: "hsl(204, 60%, 50%)"
    },
    roof: {
      main: "hsl(219, 20%, 60%)"
    }
  },
  trs: {
    bay: {
      main: "hsl(240, 30%, 70%)",
      selected: "hsl(240, 80%, 80%)",
      details: "hsl(309, 100%, 25%)",
      preview: "hsl(204, 30%, 70%)"
    },
    clothing: "hsl(180, 90%, 20%)",
    roof: {
      main: "hsl(219, 40%, 70%)",
      support: "hsl(240, 30%, 75%)"
    }
  }
};

export default lightPalette;
