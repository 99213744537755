import { ReactElement } from "react";
import TextProps from "./Text.types";
import { Typography } from "@mui/material";

const Text = (props: TextProps): ReactElement => {
  const { align, children, color, sx, variant } = props;

  return (
    <Typography
      align={align}
      color={color}
      sx={{ userSelect: "none", ...sx }}
      variant={variant}
    >
      {children}
    </Typography>
  );
};

export default Text;
