import { useStoreWithUndo } from "store/store";

/** Snackbar */
export const useSnackbarActions = () =>
  useStoreWithUndo((state) => state.snackbarActions);
export const useSnackbarOpen = () =>
  useStoreWithUndo((state) => state.snackbarOpen);
export const useSnackbarText = () =>
  useStoreWithUndo((state) => state.snackbarText);
export const useSnackbarType = () =>
  useStoreWithUndo((state) => state.snackbarType);
