import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { LedgerSlice } from "./ledger.interface";
import {
  addLedgersToGraph,
  removeLedgersFromGraph,
  setLedgersInGraph
} from "./ledger.utils";

/** Initial state */
const initialLedgerState = {
  ledgers: []
};

/** Slice creation */
const createLedgerSlice: StateCreator<Store, [], [], LedgerSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialLedgerState));

  /** Return state */
  return {
    ...initialLedgerState,
    addLedgers: (ledgers) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addLedgersToGraph({ graph: newGraph, ledgers });

        return {
          ledgers: [...state.ledgers, ...ledgers],
          graph: newGraph
        };
      });
    },
    setLedgers: (ledgers) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setLedgersInGraph({
          graph: newGraph,
          newLedgers: ledgers,
          oldLedgers: [...state.ledgers]
        });

        return {
          ledgers,
          graph: newGraph
        };
      });
    },
    removeLedgers: (ids) => {
      set((state) => {
        const removeLedgers = [...state.ledgers];
        const newGraph = state.graph.copy();
        removeLedgersFromGraph({ graph: newGraph, ledgers: removeLedgers });
        return {
          ledgers: state.ledgers.filter((ledger) => !ids.includes(ledger.id)),
          graph: newGraph
        };
      });
    }
  };
};

export default createLedgerSlice;
